import React, { Component } from 'react'

class Hamburger extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isActive: false,
      url: typeof window !== 'undefined' ? window.location.href : ''
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const newUrl = typeof window !== 'undefined' ? window.location.href : ''
    if (prevState.url !== newUrl) {
      this.setState({
        isActive: false,
        url: newUrl
      })
    }
  }

  handleChange = () => {
    this.setState({isActive: !this.state.isActive})
  }

  render () {
    return (
      <button onClick={this.handleChange} className={`hamburger hamburger--spin ${this.state.isActive? 'is-active' : ''}`} type="button">
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
    )
  }
}

export default Hamburger