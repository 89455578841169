import React from 'react'
import { Link } from 'gatsby'

import IconVideos from '../images/icon-videos.inline.svg'
import IconDocuments from '../images/icon-documents.inline.svg'
import IconTutorials from '../images/icon-tutorials.inline.svg'

const navItems = [
  {
    title: 'Videos',
    icon: IconVideos,
    target: '/resources/'
  },
  {
    title: 'Documents',
    icon: IconDocuments,
    target: '/resources/documents/'
  },
  {
    title: 'Tutorials',
    icon: IconTutorials,
    target: '/resources/tutorials/'
  }
]

const NavItem = ({item}) => (
  // <Link to={item.target} className={item.selected? 'hero-nav__item selected' : 'hero-nav__item'}>
  <Link to={item.target} className="hero-nav__item" activeClassName="selected">
      <div className="hero-nav__item-icon">
        <item.icon />
      </div>
      <div className="hero-nav__item-title">{item.title}</div>
  </Link>
)


export default () => (
  <nav className="hero-nav">
    <div>
      {navItems.map((item, index) => (
        <NavItem key={index} item={item} />
      ))}
    </div>
  </nav>
)