import React from 'react'

import Header from '../components/header'
import Footer from '../components/footer'

import HalfHero from '../components/half-hero'
import ProductsNav from '../components/products-nav'
import ResourcesNav from '../components/resources-nav'

import "./style.scss"

class DefaultLayout extends React.Component {
  
  render() {
    const { children, pageContext } = this.props
    
    if (pageContext.layout === 'products') {
      return (
        <>
          <Header />
          <div className="grid-layout">
            <HalfHero fullWidth />
            <ProductsNav />
          </div>

          <main className="grid-layout">
            {children}
          </main>
          <Footer />
        </>
      )

    } else if (pageContext.layout === 'resources') { 
      return (
        <>
          <Header />
          <div className="grid-layout">
            <HalfHero fullWidth />
            <ResourcesNav />
          </div>

          <main className="grid-layout">
            {children}
          </main>
          <Footer />
        </>

      )
    } else if (pageContext.layout === 'tc') {
      return (
        <>
          <Header />
          <main className="grid-layout">
            {children}
          </main>
          <Footer withoutContactForm />
        </>
      )
    } else {
      return (
        <>
          <Header />
          <main className="grid-layout">
            {children}
          </main>
          <Footer />
        </>
      )
      
    }

  }
}

export default DefaultLayout