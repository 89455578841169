import React from 'react'
import Bg from './bg'

const Footer = ({withoutContactForm}) => {
  return (
    <footer className="footer">
      <Bg
        filename='background-homepage-footer.jpg'
        className='footer__bg'
      >
        {!withoutContactForm &&
          <div className="form-wrapper contact-form">
            <h2>Contact us</h2>

            <form method="post" action="#">
              <input type="text" placeholder="First name *" name="FirstName" id="FirstName" required />
              <input type="text" placeholder="Last name *" name="LastName" id="LastName" required />
              <input type="text" placeholder="E-mail *" name="Email" id="Email" required />
              <input type="text" placeholder="Phone number" name="PhoneNumber" id="PhoneNumber" />
              <input className="message *" type="text" placeholder="Message" name="Message" id="Message" required />
              <div className="submit-wrap">
                <button className="white" type="submit">Send</button>
              </div>
            </form>
          </div>
        }
        {withoutContactForm &&
          <div style={{height: '200px'}} />
        }

        <div className="clip clip--top" />
      </Bg>
    </footer>
  )
}

export default Footer