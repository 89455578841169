import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

export default function({ children, filename, className, style, fadeIn }) {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: allFile(filter: { sourceInstanceName: { eq: "markdown-pages"}}) {
        nodes {
          relativePath
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)

  const image = data.placeholderImage.nodes.find(node => node.relativePath === filename)

  if (!image) return null

  return (
    <BackgroundImage
      fluid={image.childImageSharp.fluid}
      className={className}
      style={style}
      loading='eager'
      fadeIn={false}
      critical={true}
    >
      {children}
    </BackgroundImage>
  )
}