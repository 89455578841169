import React from 'react'
import Bg from './bg'

const HalfHero = ({fullWidth, children}) => {
  const className = (fullWidth)? 'half-hero full-width' : 'half-hero'
  
  return (
    <section className={className}>
      <Bg
        filename='background-pages-header.jpg'
        className='half-hero__bg'
        style={{
          backgroundPosition: 'center top'
        }}
      >
        {children}
      </Bg>
    </section>
  )
}

export default HalfHero
