// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/AboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-eula-js": () => import("./../src/pages/EULA.js" /* webpackChunkName: "component---src-pages-eula-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-key-verification-js": () => import("./../src/pages/KeyVerification.js" /* webpackChunkName: "component---src-pages-key-verification-js" */),
  "component---src-pages-products-about-js": () => import("./../src/pages/products/about.js" /* webpackChunkName: "component---src-pages-products-about-js" */),
  "component---src-pages-products-benefits-js": () => import("./../src/pages/products/benefits.js" /* webpackChunkName: "component---src-pages-products-benefits-js" */),
  "component---src-pages-products-index-js": () => import("./../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-registration-js": () => import("./../src/pages/Registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-resources-documents-js": () => import("./../src/pages/resources/documents.js" /* webpackChunkName: "component---src-pages-resources-documents-js" */),
  "component---src-pages-resources-index-js": () => import("./../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-resources-tutorials-js": () => import("./../src/pages/resources/tutorials.js" /* webpackChunkName: "component---src-pages-resources-tutorials-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../src/pages/TermsAndConditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

