import React from "react"
import Logo from '../assets/rigpro-logo.inline.svg'
import { Link } from "gatsby"
import { useInView } from 'react-intersection-observer'
import Hamburger from "../components/hamburger"

const Header = () => {
  const [ ref, inView] = useInView({
    treshold: 0
  })

  return (
    <>
      <div className="header-observer" ref={ref} />
      <header className={inView ? 'header' : 'header sticky'}>
        <div className="header-wrapper">
          <div className="logo">
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <Hamburger />
          <nav>
            <ul>
              <li><Link to="/products/" activeClassName="active" partiallyActive={true}>Products</Link></li>
              <li><Link to="/resources/" activeClassName="active" partiallyActive={true}>Resources</Link></li>
              <li className="spacer"></li>
              <li><Link to="/TermsAndConditions">Register</Link></li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  )
}

export default Header
